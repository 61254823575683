<template>
  <v-select
    :options="status === 'initial' ? [] : data"
    :label="label"
    :placeholder="status === 'initial' ? 'Loading...' : ''"
    :reduce="item => item.id"
    multiple
    @search="onChange"
    @input="onInput"
  />
</template>

<script>
import vSelect from 'vue-select'
import { get } from 'vuex-pathify'
import { debounce } from 'lodash'
import store from '@/store'

export default {
  components: {
    vSelect,
  },
  props: {
    moduleName: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      default: () => ({
        search: '',
      }),
      required: false,
    },
    sortBy: {
      type: String,
      default: 'id',
      required: false,
    },
    sortDesc: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      // eslint-disable-next-line func-names
      handler: debounce(function () {
        this.onFilterChange()
      }, 500),
    },
  },
  beforeCreate() {
    const { moduleName } = this.$options.propsData
    if (!this.$options.computed) {
      this.$options.computed = {}
    }
    Object.assign(this.$options.computed, {
      ...get(moduleName, ['data', 'status']),
    })
  },
  mounted() {
    store.dispatch(`${this.moduleName}/setSort`, { column: this.sortBy, dir: this.sortDesc ? 'DESC' : 'ASC' })
    store.dispatch(`${this.moduleName}/loadAll`)
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    },
    onChange(search) {
      this.filters.search = search
      debounce(function () {
        this.onFilterChange()
      }, 500)
    },
    onFilterChange() {
      store.dispatch(`${this.moduleName}/changeFilters`, this.filters)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
