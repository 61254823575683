import axiosInstance from '@/plugins/axios'

export default {
  fetchAllRules() {
    const url = 'admin/security/rules'

    return axiosInstance.get(url)
      .then(response => response.data)
  },
  fetchAdminUsers() {
    const url = 'admin/security/users'

    return axiosInstance.get(url)
      .then(response => response.data)
  },

  addUserToAdmin(id, roles = []) {
    const url = `admin/security/users/${id}`

    const data = { roles }

    return axiosInstance.post(url, data)
      .then(response => response.data)
  },

  removeUserFromAdmin(id) {
    const url = `admin/security/users/${id}`

    return axiosInstance.delete(url)
      .then(response => response.data)
  },
}
