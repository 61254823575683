<template>
  <b-modal
    id="modal-user-to-role"
    ref="modal-user-to-role"
    cancel-variant="outline-secondary"
    ok-title="Create"
    cancel-title="Close"
    centered
    size="lg"
    title="Assign users to role"
    :no-close-on-backdrop="true"
    @ok="handleOk"
  >
    <b-form>
      <label>Users</label>
      <custom-multiple-select
        v-model="usersIds"
        module-name="usersList"
        label="email"
      />
    </b-form>
  </b-modal>
</template>
<script>
import {
  BModal, BForm, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { dispatch, get } from 'vuex-pathify'
import CustomMultipleSelect from '@/components/ui/forms/CustomMultipleSelect.vue'

export default {
  components: {
    BModal,
    BForm,
    CustomMultipleSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    roleId: String,
  },
  data() {
    return {
      usersIds: [],
    }
  },
  computed: {
    ...get('rolesList@row', { users: 'users' }),
  },
  methods: {
    handleOk(event) {
      // prevent modal before immediate closing
      event.preventDefault()
      const users = []
      this.usersIds.forEach(id => {
        users.push({ id })
      })
      this.users.forEach(user => {
        users.push({ id: user.id })
      })

      this.loading = true

      dispatch('rolesList/update', { id: this.roleId, data: { users } })
        .then(() => {
          dispatch('rolesList/get', this.roleId)
          this.loading = false
          this.$refs['modal-user-to-role'].hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated',
              icon: 'SendIcon',
              text: 'Users was assign',
              variant: 'success',
            },
          })
          this.usersIds = []
        })
    },
  },
}
</script>
