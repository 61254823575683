<template>
  <div>
    <b-card v-if="loaded">
      <b-card-header>
        <b-row>
          <b-col>
            <b-button-group>
              <b-button
                v-b-modal.modal-user-to-role
                variant="primary"
              >
                Add user
              </b-button>
              <b-button
                v-b-modal.modal-rule-to-role
                variant="secondary"
              >
                Add rule
              </b-button>
            </b-button-group>
          </b-col>
          <b-col>
            <h2 class="ml-auto mb-1">
              | {{ name }}
            </h2>
          </b-col>
        </b-row>
      </b-card-header>

      <users-table
        :role-id="roleId"
      />
      <hr>
      <app-collapse
        class="mb-2"
      >
        <app-collapse-item
          v-if="rules.length > 0"
          title="Rules"
        >
          <rules-table :role-id="roleId" />
        </app-collapse-item>
      </app-collapse>
    </b-card>
    <span v-else>Loading</span>
    <user-to-role-modal :role-id="roleId" />
    <rule-to-role-modal :role-id="roleId" />
  </div>
</template>
<script>
import {
  BCard, BCardHeader, BButtonGroup, BButton, BCol, BRow,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { dispatch, get } from 'vuex-pathify'
import UsersTable from '@/views/security/role/detail/components/UsersTable.vue'
import RulesTable from '@/views/security/role/detail/components/RulesTable.vue'
import UserToRoleModal from '@/views/security/role/detail/components/UserToRoleModal.vue'
import RuleToRoleModal from '@/views/security/role/detail/components/RuleToRoleModal.vue'

export default {
  components: {
    RuleToRoleModal,
    UserToRoleModal,
    RulesTable,
    AppCollapse,
    AppCollapseItem,
    UsersTable,
    BCard,
    BCardHeader,
    BButtonGroup,
    BButton,
    BCol,
    BRow,
  },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    roleId() {
      return this.$route.params.id
    },
    ...get('rolesList@row', {
      rules: 'rules', users: 'users', name: 'name',
    }),
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    loadData() {
      dispatch('rolesList/get', this.roleId)
        .then(() => {
          this.loaded = true
        }).catch(err => {
          if (err.status === 404) {
            this.$router.push({ name: 'error-404' })
          }
        })
    },
  },
}
</script>
