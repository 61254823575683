<template>
  <b-modal
    id="modal-rule-to-role"
    ref="modal-rule-to-role"
    cancel-variant="outline-secondary"
    ok-title="Create"
    cancel-title="Close"
    centered
    size="lg"
    title="Assign rules to role"
    :no-close-on-backdrop="true"
    @ok="handleOk"
  >

    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <b-form
        v-if="!loading"
      >
        <label>Rules</label>
        <v-select
          v-model="rulesIds"
          :options="options"
          :placeholder="loading ? 'Loading...' : ''"
          :reduce="item => item.id"
          multiple
          label="name"
        />
      </b-form>
    </b-overlay>
  </b-modal>
</template>
<script>
import {
  BModal, BForm, VBModal, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { dispatch, get } from 'vuex-pathify'
import vSelect from 'vue-select'
import SecurityAPI from '@/api/security'

export default {
  components: {
    BModal,
    BForm,
    BOverlay,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    roleId: String,
  },
  data() {
    return {
      loading: true,
      rulesIds: [],
      options: [],
    }
  },
  computed: {
    ...get('rolesList@row', { rules: 'rules' }),
  },
  beforeMount() {
    SecurityAPI.fetchAllRules().then(response => {
      this.options = response
      this.loading = false
    })
  },
  methods: {
    handleOk(event) {
      // prevent modal before immediate closing
      event.preventDefault()
      const rules = []
      this.loading = true
      this.rulesIds.forEach(id => {
        rules.push({ id })
      })

      this.rules.forEach(rule => {
        rules.push({ id: rule.id })
      })

      dispatch('rolesList/update', { id: this.roleId, data: { rules } })
        .then(() => {
          dispatch('rolesList/get', this.roleId)
          this.loading = false
          this.$refs['modal-rule-to-role'].hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated',
              icon: 'SendIcon',
              text: 'Roles was assign',
              variant: 'success',
            },
          })
          this.rulesIds = []
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
