<template>
  <div>
    <b-table
      :items="rules"
      :fields="fields"
      striped
      responsive
      small
      :per-page="perPage"
      :current-page="currentPage"
      :busy="loading"
    >

      <template #cell(actions)="data">
        <b-button
          variant="outline-danger"
          class="btn-icon"
          @click="onRemove(data.item.id)"
        >
          <feather-icon icon="Trash2Icon" />
        </b-button>
      </template>
    </b-table>
    <pagination-row />
  </div>
</template>

<script>
import { BTable, BButton } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { dispatch, get } from 'vuex-pathify'
import PaginationRow from '@/components/ui/tables/PaginationRow.vue'
import table from '@/mixins/table'

export default {
  components: {
    PaginationRow,
    BTable,
    BButton,
  },
  mixins: [table],
  props: {
    roleId: String,
  },
  computed: {
    ...get('rolesList@row', { rules: 'rules' }),
  },
  data() {
    return {
      loading: false,
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'actions',
          sortable: false,
        },
      ],
    }
  },
  watch: {
    users() {
      this.totalRows = this.rules.length
    },
  },
  created() {
    this.totalRows = this.rules.length
  },
  methods: {
    onRemove(id) {
      const rulesList = this.rules.filter(rule => rule.id !== id)

      dispatch('rolesList/update', { id: this.roleId, data: { rules: rulesList } })
        .then(() => {
          dispatch('rolesList/get', this.roleId)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Deleted',
              icon: 'SendIcon',
              text: 'Rule was removed form role',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>
